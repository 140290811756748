
export function makeImageUrl(photo) {
  let result = null;
  if (typeof photo == 'object' && photo.type && photo.data) {
    result = 'data:image/' + photo.type + ';base64,' + photo.data;
  }
  return result;
}
export function notifyBrowser(options = {}) {
  if (window.Notification.permission !== 'granted') {
    window.Notification.requestPermission();
  }
  else {
    options['title'] = options['title'] ? options['title'] : 'Vnpsoftware';
    // options['icon'] = options['icon'] ? options['icon'] : require('../img/icon-chat.svg');
    options['body'] = options['body'] ? options['body'] : '';
    options['link'] = options['link'] ? options['link'] : window.location.origin;
    var notification = new window.Notification(options['title'], {
      icon: options['icon'],
      body: options['body'],
    });
    notification.onclick = function () {
      window.open(options['link']);
    };
    setTimeout(() => {
      notification.close();
    }, 3500)
  }
}

export function change_alias(alias) {
  var str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
  str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
  str = str.replace(/đ/g,"d");
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
  str = str.replace(/ + /g," ");
  str = str.trim(); 
  return str;
}

export function formatPrice(price) {
  return new Intl.NumberFormat('en-US').format(price) +'$';
}