import React from "react";
import { Popover } from 'antd';
import { AccountMetaData, dimens } from '../config/app';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }
    componentDidMount = () => {
       
    }
    render() {
        return (
            <header id="page-header">
                <div className="content-header">
                    <div className="content-header-section">
                        <button type="button" className="btn btn-circle btn-dual-secondary btn-toggle-sidebar d-flex align-items-center justify-content-center" data-toggle="sidebar" data-action="sidebar_toggle">
                            <i className="fa fa-navicon" style={{ fontSize: "20px" }}></i>
                        </button>
                    </div>
                    <div className="content-header-section">
                        <Popover visible={this.state.visible} content={
                            <div className="" style={{ minWidth: "150px" }}>
                                <h5 className="h6 text-center py-10 mb-5 border-b text-uppercase">USER</h5>
                                {
                                    this.props.user.is_admin ? 
                                    <a href="/admin" className="dropdown-item d-flex align-items-center"><i className="far fa-user-crown mr-10"></i> Admin</a> : null
                                }
                                <a href="/me" className="dropdown-item d-flex align-items-center"><i className="far fa-user mr-10"></i> Account</a>
                                <a href="/payment/history" className="dropdown-item d-flex align-items-center"><i className="far fa-usd-circle mr-10"></i> Payment History</a>
                                <a href="#" className="dropdown-item d-flex align-items-center" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        visible: false
                                    })
                                    this.props.showVisibleChangePassword();
                                }}><i className="far fa-lock mr-10"></i> Reset Password</a>
                                <a className="dropdown-item d-flex align-items-center" onClick={() => AccountMetaData.logOut()}><i className="far fa-sign-out mr-10"></i> Logout</a>
                            </div>
                        } trigger="click" placement="bottomRight" onVisibleChange={(e) => this.setState({visible: e})}>
                            <div className="btn-group align-items-center" role="group" style={{cursor: "pointer"}}>
                                <button type="button" className="btn btn-rounded" id="page-header-user-dropdown" style={{ display: "flex", alignItems: "center", paddingRight: "0px" }}>
                                    <a className="img-link mr-2" href="#">
                                        <img className="img-avatar" src={this.props.user.avatar} alt="" style={{ width: "35px", height: "35px" }} 
                                            onError={(e) => e.target.src = "/user.jpg"}
                                        />
                                    </a>
                                    <span>{this.props.user.fullname}</span>
                                </button>
                                <div className="ml-10 d-flex flex-column justify-content-center info-name">
                                    <p className="mb-0"> <i className="fa fa fa-angle-down"></i></p>
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>
            </header>
        )
    }
}
