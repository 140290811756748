import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { fetchData } from '../lib/apis';
import { AccountMetaData, setCookie } from '../config/app';

export default class LoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataForm: {},
            loading: false
        }
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true })
            
            let resutl = await fetchData({
                url: 'api/v1/user/login',
                method: 'post',
                data: values
            });
            setCookie('auth', resutl['token'], 7);

            this.setState({ loading: false }, () => {
                this.props.notification({
                    type: 'success',
                    title: 'Login',
                    content: 'Login success'
                })
                this.props.history.push('/');
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    type: 'error',
                    title: 'Login',
                    content: err.message || 'Đăng nhập thất bại!'
                })
            });
        }
    };
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    render() {
        return (
            <div id="page-container" className="main-content-boxed">
                <main id="main-container">
                    <div className="bg-body-dark bg-pattern">
                        <div className="row mx-0 justify-content-center">
                            <div className="hero-static col-lg-6 col-xl-4">
                                <div className="content content-full overflow-hidden">
                                    <div className="py-30 text-center">
                                        <h1 className="h4 font-w700 mt-30 mb-10">Welcome SMS</h1>
                                    </div>
                                    <div className="block block-themed block-rounded block-shadow">
                                        <div className="block-header bg-gd-dusk">
                                            <h3 className="block-title">Login</h3>
                                            <div className="block-options">
                                                <button type="button" className="btn-block-option">
                                                    <i className="si si-wrench"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="block-content">
                                            <Form
                                                onFinish={this.onFinish}
                                                name="basic"
                                                className="form-login"
                                                autoComplete="off"
                                            >
                                                <Form.Item
                                                    name="email"
                                                    label="Username"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    name="password"
                                                    label="Password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password />
                                                </Form.Item>
                                                <Form.Item className="text-right">
                                                    <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                                        Next
                                                        </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                        {/* <div className="block-content bg-body-light">
                                            <div className="form-group text-center">
                                                <Link className="link-effect text-muted mr-10 mb-5 d-inline-block" to="register">
                                                    <i className="fa fa-user mr-5"></i> Đăng ký
                                                    </Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
        )
    }
}
