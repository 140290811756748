import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { message, Spin, notification, Modal } from 'antd'
import { fetchData, requestApiFb } from './lib/apis';
import { setCookie, AccountMetaData } from './config/app';
import { formatPrice } from './lib/helpers'

import { MainlayoutView, LoginView, RegisterView } from './views';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import moment from 'moment';

class AppComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
    this.networks = ["Viettel", "MobiPhone", "VinaPhone", "Vietnamobile", "Gmobile"]
  }
  componentDidMount = () => {

  }

  notification = (options) => {
    let { type, title, content, key, duration } = options;
    notification[type]({
      message: title,
      description: content,
      key: key,
      duration: duration ? duration : 5
    })
  }
  message = (options) => {
    let { type, key, content, duration } = options;
    message[type]({
      content: content,
      key: key,
      duration: duration
    })
  }
  getDetailUser = async () => {
    try {
      let response = await fetchData({
        url: 'api/v1/user/detail',
        method: 'get'
      });
      this.setState({
        user: response.data,
        service: response.setting
      })
    } catch (err) {
      this.notification({
        type: "error",
        title: "Đăng nhập",
        content: `${err.message || "Đăng nhập thất bại"} (chuyển hướng sau 3s..)`
      })
      setTimeout(() => {
        AccountMetaData.logOut();
      }, 3000)
    }
  }

  onExport = async ({ data, fileName, fileExtension, format_export, export_title }) => {
    return new Promise((resolve, reject) => {
      try {
        fileName = fileName ? fileName : 'export-' + moment().format('HH-mm-DD-MM-YYYY');
        fileExtension = fileExtension ? fileExtension : '.txt';
        fileName += fileExtension;

        let content = null;
        let fileType = 'text/plain;charset=utf-8';
        if (fileExtension == '.txt') {
          content = '';

          // console.log(data, format_export);

          data.forEach((item, i) => {
            if (format_export) {
              content += this.getValueExport({
                data: item,
                format_export: format_export,
                type: 'string'
              });
              if (i < data.length - 1) content += `\n`;
            } else {
              content += this.getValueExport({
                data: item,
                type: 'string'
              });
              if (i < data.length - 1) content += `\n`;
            }
          })
        } else if (fileExtension == '.xlsx') {
          fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let data_excel = [];
          data.forEach((item, i) => {
            if (format_export) {
              if(item['time_send']) item['time_send'] = moment(item['time_send']).format('DD/MM/YYYY HH:mm:ss');
              if(typeof item['status'] == "number"){
                item['status'] = item['status'] == 0 ? "Pending" : item['status'] == 1 ? "Success" : item['status'] == 2 ? "Failed" : "Unknown"
              }

              data_excel.push(this.getValueExport({
                data: item,
                format_export: format_export,
                type: 'object',
                export_title: export_title
              }))
            }
          })
          const ws = XLSX.utils.json_to_sheet(data_excel);
          const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
          content = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        }

        var blob = new Blob([content], { type: fileType });
        setTimeout(() => {
          FileSaver.saveAs(blob, fileName);
          resolve(true)
        }, 300)
      } catch (err) {
        reject(err);
      }
    })
  };

  getValueExport = ({ data, format_export, type, export_title }) => {
    try {
      if (typeof data == 'string') {
        return data;
      } else {
        type = type ? type : 'object';
        let obj_export = {};
        export_title = export_title || {};
        let string_export = '';
        format_export.forEach((format, i) => {
          let obj = '';
          export_title[format] = export_title[format] || format;

          obj_export[`${export_title[format]}`] = '';

          format.split('.').forEach((format_export) => {
            obj = obj ? obj[format_export] : data[format_export];
          })

          if (Array.isArray(obj)) {
            obj_export[`${export_title[format]}`] = obj.join(', ');
            string_export += obj.join(', ');
          } else if (obj !== '' && obj !== undefined) {
            obj_export[`${export_title[format]}`] = obj;
            string_export += obj;
          }
          if (i < format_export.length - 1) string_export += '|';
        })

        return type == 'object' ? obj_export : string_export;
      }
    } catch (err) {
      console.log(err);
    }

  }

  render() {
    let prop_m = {
      notification: this.notification,
      message: this.message,
      user: this.state.user,
      updateUser: (user) => this.setState({ user: user }),
      getDetailUser: this.getDetailUser,
      fetchData: fetchData,
      service: this.state.service,
      networks: this.networks,
      AccountMetaData: AccountMetaData,
      formatPrice: formatPrice,
      onExport: this.onExport
    }
    return (
      <Router>
        <Switch>
          <Route
            exact
            path='/login'
            render={props => <LoginView {...props} {...prop_m} />}
          />
          <Route
            exact
            path='/register'
            render={props => <RegisterView {...props} {...prop_m} />}
          />
          <Route
            path='/'
            render={props => <MainlayoutView {...props} {...prop_m}
            />}
          />

        </Switch>
      </Router>
    )
  }
}
export default AppComponent;
