import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, DatePicker } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import qs from 'qs';
import Chart from 'chart.js/auto';
import locale from 'antd/es/date-picker/locale/vi_VN';

export default class StatisticalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statistical: [],
            total: null
        };
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {

            }
            if(this.formFilter){
                params = {
                    ...params,
                    ...this.formFilter.getFieldsValue()
                }
                delete params['date_picker'];
            }

            let response = await fetchData({
                url: `api/v1/ow/statistical?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({
                statistical: response.data,
                total: response.total,
                loading: false
            }, () => {
                this.showChart(response.data);
                this.showChart2(response.data);
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    showChart = (options) => {
        var config = {
            type: 'line',
            data: {
                labels: options.map((item) => item.date),
                datasets: [{
                    label: '#Người dùng',
                    data: options.map((item) => item.total_user),
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                },
                {
                    label: '#Giao dịch',
                    data: options.map((item) => item.total_transaction),
                    backgroundColor: ["rgba(255,0,0,0.2)"],
                    borderColor: ["rgba(255,0,0,1)"],
                    borderWidth: 1
                }
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
        if (!window.myLine) {
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        } else {
            document.querySelector('#block-myChart #myChart').remove();
            document.getElementById('block-myChart').innerHTML = '<canvas id="myChart" width="400" height="120"></canvas>';
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        }

    }

    showChart2 = (options) => {
        var config = {
            type: 'bar',
            data: {
                labels: options.map((item) => item.date),
                datasets: [
                    {
                        label: '#Đơn hàng',
                        data: options.map((item) => item.total_order),
                        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
                        borderColor: ["rgba(54, 162, 235, 1)"],
                        borderWidth: 1
                    },
                    {
                        label: '#Thành công',
                        data: options.map((item) => item.total_order_success),
                        backgroundColor: ["rgba(110,171,77,0.2)"],
                        borderColor: ["rgba(110,171,77)"],
                        borderWidth: 1
                    },
                    {
                        label: '#Lỗi',
                        data: options.map((item) => item.total_order_failed),
                        backgroundColor: ["rgba(255,0,0,0.2)"],
                        borderColor: ["rgba(255,0,0,1)"],
                        borderWidth: 1
                    },
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
        if (!window.myLine2) {
            var ctx = document.querySelector('#block-myChart-2 #myChart').getContext('2d');
            window.myLine2 = new Chart(ctx, config);
        } else {
            document.querySelector('#block-myChart-2 #myChart').remove();
            document.getElementById('block-myChart-2').innerHTML = '<canvas id="myChart" width="400" height="120"></canvas>';
            var ctx = document.querySelector('#block-myChart-2 #myChart').getContext('2d');
            window.myLine2 = new Chart(ctx, config);
        }

    }

    render() {
        let { total } = this.state;
        return (
            <div className="content">
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Thống kê</h3>
                    </div>
                </div>
                <div className="block block-rounded" style={{ minHeight: "auto", background: "transparent" }}>
                    <div class="row">
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-users fa-2x text-pulse"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-pulse" >{total ? total.total_user : 0}</div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Người dùng</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-wallet fa-2x text-earth-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-earth"><span>{this.props.formatPrice(total ? total.total_amount : 0)}</span></div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Thu nhập</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-bag fa-2x text-primary-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-primary" >{total ? total.total_transaction : 0}</div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Giao dịch</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-6 col-xl-3">
                            <a class="block block-rounded block-bordered block-link-shadow" href="javascript:void(0)">
                                <div class="block-content block-content-full clearfix">
                                    <div class="float-right mt-15 d-none d-sm-block">
                                        <i class="si si-envelope-open fa-2x text-elegance-light"></i>
                                    </div>
                                    <div class="font-size-h3 font-w600 text-elegance" >{total ? total.total_order : 0}</div>
                                    <div class="font-size-sm font-w600 text-uppercase text-muted">Đơn hàng</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block block-rounded" style={{ minHeight: "auto" }}>
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Người dùng</h3>
                    </div>
                    <div className="block-header pb-10 justify-content-between align-items-center">
                        <div></div>
                        <div>
                            <Form
                                ref={(evt) => this.formFilter = evt }
                                initialValues={{
                                    start: moment().add(-7, 'day').format('YYYY-MM-DD'),
                                    end: moment().format('YYYY-MM-DD')
                                }}
                            >
                                <Form.Item name="start" noStyle>
                                    <Input type="hidden"/>
                                </Form.Item>
                                <Form.Item name="end" noStyle>
                                    <Input type="hidden"/>
                                </Form.Item>

                                <Form.Item name="date_picker">
                                    <DatePicker.RangePicker
                                        locale={locale}
                                        defaultValue={[moment().add(-7, 'day'), moment()]}
                                        ranges={{
                                            'Tuần này': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                            'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                        }}
                                        onChange={(moments, dateString) => {
                                            this.formFilter.setFieldsValue({
                                                start: dateString[0] ? moment(dateString[0]).format('YYYY-MM-DD') : "",
                                                end: dateString[1] ? moment(dateString[1]).format('YYYY-MM-DD') : ""
                                            })
                                            this.getListData();
                                        }}
                                        format="YYYY/MM/DD"
                                        allowClear={[false, false]}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <Spin spinning={this.state.loading} tip="loading..">
                        <div className="block-content" id="block-myChart">
                            <canvas id="myChart" width="400" height="120"></canvas>
                        </div>
                    </Spin>
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Đơn hàng</h3>
                    </div>
                    <Spin spinning={this.state.loading} tip="loading..">
                        <div className="block-content" id="block-myChart-2">
                            <canvas id="myChart" width="400" height="120"></canvas>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}
