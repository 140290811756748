import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { AccountMetaData } from '../../config/app';
import { fetchData } from '../../lib/apis';

import { Header, SiderBar, UpgradePackage, DetailPayment, ChangePassword } from '../../component';
import {
    HomeView, View404, PackageView, MeView, AdminTransactionView, AdminUserView, AdminNickView, ClientView, LogView, StatisticalView, ProxyView,
    ShopOrderView, SettingView, FolderNickView, AdminSimView, TaskView, StatisticalClienlView, AdminMessageView
} from '../index';

export default class MainlayoutView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
            service: null
        };
    }
    componentWillMount = async () => {
        if (!AccountMetaData.checkIssetLogin()) {
            AccountMetaData.logIn();
        } else {
            this.props.getDetailUser();
        }
    }
    componentDidMount = async () => {

    }

    activeTab = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let tabid = e.target.getAttribute("href") ? e.target.getAttribute("href").replace('#', '') : e.target.parentNode.getAttribute("href").replace('#', '');
        // this.setState({
        //     type: tabid
        // })
    }

    showUpgrade = async (type) => {
        try {
            type = type ? type : 'scan_uid';
            await this.props.getDetailUser();
            this.setState({
                visibleUpgradePackage: true,
                typeUpgradePackage: type
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Chi tiết đăng ký gói cước',
                type: 'error'
            })
        }
    }
    handleChecked = (e) => {
        try {
            let table = e.target.closest('table');
            let items = table.querySelectorAll('[name="check_item"]');
            let num_checked = 0;
            items.forEach((item) => {
                if (item.checked) num_checked++;
            })
            if (e.target.name == 'checkall') {
                if (items.length == num_checked) {
                    items.forEach((item) => {
                        item.checked = false;
                    })
                    num_checked = 0;
                } else {
                    items.forEach((item) => {
                        item.checked = true;
                    })
                    num_checked = items.length;
                }
            }
            if (items.length == num_checked) {
                table.querySelector('[name="checkall"]').checked = true;
            } else {
                table.querySelector('[name="checkall"]').checked = false;
            }
        } catch (err) {
            console.log(err);
        }
    }
    sleep = async (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms)
        })
    }
    copyText = (text) => {
        let el = document.querySelector('#input-copy');
        el.textContent = text;
        el.select();
        document.execCommand("copy");
        this.props.notification({
            title: 'Copy text',
            content: 'Success',
            type: "success"
        });
    }

    handleFilterData = ({ type, input, getFunction }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                getFunction({ type: 'reset' });
            }, 300);
        } else {
            getFunction({ type: 'reset' });
        }
    }

    render() {
        if (!this.props.user) return null;
        var props_m = {
            ...this.props,
            activeTab: this.activeTab,
            showUpgrade: this.showUpgrade,
            hideUpgrade: () => this.setState({ visibleUpgradePackage: false }),
            showDetailPayment: (transaction) => this.setState({ detailBanking: transaction, visibleDetailPayment: true }),
            reloadPayment: this.state.reloadPayment,
            handleChecked: this.handleChecked,
            sleep: this.sleep,
            copyText: this.copyText,
            showVisibleChangePassword: () => this.setState({ visibleChangePassword: true }),
            handleFilterData: this.handleFilterData
        }
        return (
            <div id="page-container" className="sidebar-o enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled">
                <Helmet>
                    <title>SMS</title>
                </Helmet>

                <Header {...props_m} />
                <SiderBar {...props_m} />
                <DetailPayment {...props_m} visible={this.state.visibleDetailPayment} detailBanking={this.state.detailBanking}
                    hide={() => this.setState({ visibleDetailPayment: false })}
                />
                <ChangePassword {...props_m} visible={this.state.visibleChangePassword} hide={() => this.setState({ visibleChangePassword: false })} />

                <textarea id="input-copy" style={{ opacity: 0, position: 'absolute', zIndex: 0, width: "1px", height: "1px", top: "-100px" }} onKeyPress={(e) => { e.preventDefault(); return false }} readOnly />

                <main id="main-container">
                    <Switch>
                        <Route
                            exact
                            path='/payment/history'
                            render={props => <PackageView {...props_m} {...props} />}
                        />
                        <Route
                            exact
                            path='/me'
                            render={props => <MeView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/task'
                            render={props => <TaskView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/statistical'
                            render={props => <StatisticalClienlView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin'
                            render={props => <StatisticalView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/transaction'
                            render={props => <AdminTransactionView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/users'
                            render={props => <AdminUserView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/device'
                            render={props => <ClientView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/setting'
                            render={props => <SettingView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/sim/list'
                            render={props => <AdminSimView {...props_m} {...props} />}
                        />

                        <Route
                            exact
                            path='/admin/message/list'
                            render={props => <AdminMessageView {...props_m} {...props} />}
                        />

                        <Route
                            path="/"
                            exact
                            render={props => <HomeView {...props_m} {...props} />}
                        />
                        <Route
                            path="*"
                            render={props => <View404 {...props} />}
                        />
                    </Switch>
                </main>
            </div>
        )
    }
}
