import React, { Component } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, Radio, InputNumber } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';
import { formatPrice } from '../lib/helpers';

export default class Upgrade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.form = null;
    }
    componentDidMount = () => {

    }
    componentWillUpdate = (nextProps, nextState) => {
        
    }

    upgrade = async (values) => {
        try{
            this.props.showUpgrade();
            return
            this.setState({loading : true })
            let res = await fetchData({
                url: `api/v2/service/transaction`,
                method: 'post',
                data: JSON.stringify({
                    ...values,
                    type_package: 2
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.setState({loading : false }, () => {
                this.props.hideUpgrade();
                this.props.showDetailPayment(res.data);
            })
        } catch(err){
            this.setState({loading : false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Nâng cấp gói cước',
                type: 'error'
            })
        }
    }
    render() {
        return (
            <div className="content">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="block block-rounded pb-20">
                            <div className="block-content block-content-full text-center">
                                <img className="img-avatar img-avatar80 img-avatar-thumb" src={this.props.user.avatar} alt="" style={{ width: "80px", height: "80px" }} 
                                    onError={(e) => e.target.src = "/user.jpg"}
                                />
                            </div>
                            <div className="block-content">
                               <Form
                                initialValues={{
                                    ...this.props.user
                                }}
                                className=""
                               >
                                   <Form.Item label="Full name" name="fullname">
                                       <Input />
                                   </Form.Item>
                                   <Form.Item label="Mobile number" name="phone">
                                       <Input />
                                   </Form.Item>
                                   <Form.Item label="Email" name="email">
                                       <Input />
                                   </Form.Item>
                                   <Form.Item label="Description" name="description">
                                       <Input.TextArea style={{minHeight: "160px"}}/>
                                   </Form.Item>
                               </Form>
                                <Form.Item>
                                    <Button type="primary" disabled>
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
