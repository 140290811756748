import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Checkbox, InputNumber } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';
import { UploadFile } from '../../component';

export default class SettingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false
        };
    }
    componentDidMount = () => {

    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });
            if (values['banking']) values['banking'] = values['banking'].split('\n');

            values['price_send_type_network'] = {};

            Object.keys(values).forEach((key) => {
                if (this.props.networks.indexOf(key) > -1 && values[key]) {
                    values['price_send_type_network'][key] = values[key];
                }
            })

            if(values['hotline_register']) values['hotline_register'] = values['hotline_register'].replace(/\s/g, '').split('|');

            let resutl = await this.props.fetchData({
                url: 'api/v1/ow/setting/save',
                method: 'post',
                data: values
            });

            this.setState({ loadingForm: false });
            this.props.notification({
                content: "Thao tác thành công",
                title: 'setting',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập thiết lập',
                type: 'error'
            })
        }
    }

    render() {
        let price_send_type_network = this.props.service.price_send_type_network || {};

        return (
            <div className="content">
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Thiết lập</h3>
                    </div>
                    <div className="block-content">
                        <Form
                            initialValues={{
                                code_payment: this.props.service.code_payment,
                                banking: this.props.service.banking.join('\n'),
                                file_data_sample: this.props.service.file_data_sample,
                                max_send_error: this.props.service.max_send_error,
                                ...price_send_type_network,
                                hotline_register: this.props.service.hotline_register ? this.props.service.hotline_register.join(' | ') : [],
                                link_support: this.props.service.link_support || ""
                            }}
                            onFinish={this.save}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item>
                                        <label>Api key:</label> <span>{this.props.service.token}</span>
                                    </Form.Item>
                                    <Form.Item name="code_payment" label="Nội dung chuyển khoản">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="link_support" label="Link tài liệu hướng dẫn sử dụng">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="file_data_sample" label="Url file data mẫu">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="max_send_error" label="Giới hạn lần gửi lại 1 sms lỗi">
                                        <InputNumber min={0} />
                                    </Form.Item>
                                    <Form.Item name="banking" label="Tài khoản nhận tiền" className="mb-0">
                                        <Input.TextArea style={{ minHeight: "100px" }} />
                                    </Form.Item>

                                    <Form.Item>
                                        <i>Lưu ý: Mỗi dòng 1 tài khoản (stk|chủ tài khoản|chi nhánh)</i>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item label="Thiết lập giá tin nhắn">
                                        <div className="row">
                                            {
                                                this.props.networks.map((item) => {
                                                    return <Form.Item name={item} label={item} className="mb-5 col-md-4">
                                                        <InputNumber min={0} />
                                                    </Form.Item>
                                                })
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item name="hotline_register" label="Tổng đài đăng ký tin nhắn">
                                        <Input placeHolder="191 | 190.." />
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item>
                                <Button loading={this.state.loadingForm} htmlType="submit" type="primary">Lưu thiết lập</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
