import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input, InputNumber, Upload, Checkbox, Table, Tag, Alert, Steps, DatePicker } from 'antd';
import qs from 'qs';
import moment from 'moment';
import { fetchData } from '../lib/apis';

import locale from 'antd/es/date-picker/locale/en_US';

const { Option } = Select;

export default class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            order_type: "traffic",
            fileList: [],
            listTypeOrder: [],
            buff_phone: false,
            currentFormCreate: 0
        };
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == "reset") this.page = 1;
            let query = {
                limit: this.limit,
                page: this.page,
            }
            if (this.formFilter) {
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
                delete query['date_picker'];
            }
            let response = await fetchData({
                url: `api/v1/order/list?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false,
                listTypeOrder: response.type || []
            })
        } catch (err) {
            this.setState({ loading: false })
        }
    }
    create = async (values) => {
        try {
            this.setState({ loadingForm: true });
            let formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (key == "file") {
                    if(values[key]) formData.append(key, values[key].target.files[0])
                } else {
                    formData.append(key, values[key] || "")
                }
            })

            let response = await fetchData({
                url: `api/v1/order/create`,
                method: 'post',
                data: formData,
                formdata: true
            })
            this.getListData();
            this.setState({ loadingForm: false, visibleForm: false, error: null, currentFormCreate: 0, detailDataCreate: null });
            this.form.resetFields();

            this.props.notification({
                type: "success",
                title: "Create order",
                content: "Success: " + response.num_success
            })
        } catch (err) {
            this.setState({ loadingForm: false, error: typeof err == "string" ? err : err.message || "An error occurred" });
        }
    }

    exportData = async (values) => {
        try {
            this.setState({ loadingFormExport: true });
            let listData  = [];
            let page = 1;

            while(true){
                let params  = {
                    page: page,
                    limit: 500
                }
                if (this.formFilter) {
                    params = {
                        ...params,
                        ...this.formFilter.getFieldsValue()
                    }
                    delete params['date_picker'];
                }
                let response = await fetchData({
                    url: `api/v1/order/list?${qs.stringify(params)}`,
                    method: 'get'
                });
                listData = listData.concat(response.data);
                if(response.data.length == 0) break;
                page++;
                await this.props.sleep(1000);
            }

            await this.props.onExport({
                data: listData,
                fileName: values['filename'],
                fileExtension: '.xlsx',
                format_export: ["recipient.sim", "status", "time_send", "content"],
                export_title: {
                    "recipient.sim": "Recipient",
                    "status": "Status",
                    "time_send": "Time Send",
                    "content": "Content"
                }
            })
            this.setState({ loadingFormExport: false, errorExport: null })
        } catch (err) {
            this.setState({ loadingFormExport: false, errorExport: typeof err == "string" ? err : err.message || "An error occurred" })
        }
    }

    checkData = async (e) => {
        try {
            this.setState({ loadingCheckData: true });

            let formData = new FormData();
            formData.append("file", e.target.files[0]);

            let res_check = await this.props.fetchData({
                url: 'api/v1/order/check',
                method: 'post',
                data: formData,
                formdata: true
            })
            this.setState({
                loadingCheckData: false,
                error: null,
                detailDataCreate: res_check
            })
        } catch (err) {
            this.setState({ loadingCheckData: false, error: typeof err == "string" ? err : err.message || "An error occurred" })
        }
    }

    render() {
        return (
            <div className="content">
                <Modal
                    title="Create order"
                    visible={this.state.visibleForm}
                    onCancel={() => this.setState({ visibleForm: false, currentFormCreate: 0, detailDataCreate: null, error: null }, () => this.form.resetFields())}
                    footer={null}
                >
                    <Form
                        onFinish={this.create}
                        ref={(evt) => this.form = evt}
                        initialValues={{
                            order_type: "send"
                        }}
                    >
                        {
                            this.state.error ? <Alert type="error" message={this.state.error} /> : null
                        }

                        <Form.Item name="order_type" noStyle>
                            <Input type="hidden" />
                        </Form.Item>

                        <Form.Item
                            name="before_content"
                            label="Before content"
                        >
                            <Input placeholder="text 1 | text 2" />
                        </Form.Item>
                        <Form.Item
                            name="after_content"
                            label="After content"
                        >
                            <Input placeholder="text 1 | text 2" />
                        </Form.Item>

                        <Form.Item name="file" label="File data" className="mb-10" valuePropName="fileList">
                            <Input type="file" onChange={this.checkData} />
                        </Form.Item>

                        <Form.Item className="text-right">
                            <Button size="small" onClick={(e) => {
                                if (this.props.service.file_data_sample) {
                                    window.open(this.props.service.file_data_sample);
                                }
                            }}><i className="far fa-download mr-2"></i> File sample</Button>
                        </Form.Item>
                        {
                            this.state.detailDataCreate ?
                                <div style={{ marginBottom: "20px" }}>
                                <Alert type="success" message={`Total: ${this.state.detailDataCreate.num_success}`} style={{ marginBottom: "10px" }} />
                                <Alert type="success" message={<span>
                                    Price: {this.state.detailDataCreate.price_before_discount != this.state.detailDataCreate.total_price?
                                <del style={{marginRight: "10px"}}>{this.props.formatPrice(this.state.detailDataCreate.price_before_discount)}</del>    
                                : null}
                                {this.props.formatPrice(this.state.detailDataCreate.total_price)}
                                </span>} />
                            </div> : null
                        }

                        <div className="text-right">
                            <Button type="primary" loading={this.state.loadingForm} htmlType="submit" disabled={this.state.loadingCheckData}>
                                Next
                            </Button>
                        </div>

                    </Form>
                </Modal>
                <Modal
                    title="Export data"
                    visible={this.state.visibleFormExport}
                    onCancel={() => this.setState({ visibleFormExport: false, errorExport: null }, () => this.formExport.resetFields())}
                    footer={null}
                >
                    <Form
                        onFinish={this.exportData}
                        ref={(evt) => this.formExport = evt}
                        initialValues={{
                            filename: "file-export"
                        }}
                    >
                        {
                            this.state.errorExport ? <Alert type="error" message={this.state.errorExport} /> : null
                        }

                        <Form.Item
                            name="filename"
                            label="File name"
                        >
                            <Input addonAfter=".xlsx" />
                        </Form.Item>

                        <div className="text-right">
                            <Button type="primary" loading={this.state.loadingFormExport} htmlType="submit">
                                Export
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">All: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <Tooltip title="Refresh">
                                    <Button className="mr-10" type="primary" onClick={() => this.getListData()}>
                                        <i className="far fa-refresh"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="add">
                                    <Button className="mr-10" type="primary" onClick={() => this.setState({ visibleForm: true })}>
                                        <i className="far fa-plus"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Export data">
                                    <Button type="primary" onClick={() => this.setState({ visibleFormExport: true })}>
                                        <i className="far fa-download"></i>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                                recipient_network: ""
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <Form.Item name="start_time" noStyle>
                                <Input type="hidden"/>
                            </Form.Item>
                            <Form.Item name="end_time" noStyle>
                                <Input type="hidden"/>
                            </Form.Item>

                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="task_name">
                                        <Input placeHolder="Task name"/>
                                    </Form.Item>
                                </div>
                                <div className="col-auto pl-5 pr-5">
                                    <Form.Item name="date_picker">
                                        <DatePicker.RangePicker
                                            locale={locale}
                                            defaultValue={[null, null]}
                                            ranges={{
                                                'This week': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                                'This month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                            }}
                                            onChange={(moments, dateString) => {
                                                this.formFilter.setFieldsValue({
                                                    start_time: dateString[0] ? moment(dateString[0]).unix() * 1000 : "",
                                                    end_time: dateString[1] ? moment(dateString[1]).unix() * 1000 : 0
                                                })
                                                this.getListData();
                                            }}
                                            format="YYYY/MM/DD HH:mm"
                                            allowClear={[true, true]}
                                            showTime
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Option value="">Status</Option>
                                            <Option value={0}>Pending</Option>
                                            <Option value={1}>Success</Option>
                                            <Option value={2}>Failed</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="recipient_network">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Network</Select.Option>
                                            {
                                                this.props.networks.map((item, i) => {
                                                    return <Select.Option value={item}>{item}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Tooltip title="Reset filter">
                                        <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}>Reset</Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Task name",
                                        dataIndex: "task",
                                        render: (item) => {
                                            return item ? item.task_name : ""
                                        }
                                    },
                                    {
                                        title: "Recipient",
                                        dataIndex: "recipient",
                                        render: (item) => item.sim
                                    },
                                    {
                                        title: "Content",
                                        dataIndex: "content"
                                    },
                                    {
                                        title: "Network",
                                        dataIndex: "recipient",
                                        render: (item) => item.network
                                    },
                                    {
                                        title: "Sim send",
                                        dataIndex: "sim_send",
                                        render: (item) => item ? item.sim : ""
                                    },
                                    {
                                        title: "Status",
                                        render: (item) => {
                                            return <Tag color={item.status == 0 ? "processing" : item.status == 1 ? "success" : "error"}>{item.status == 0 ? "Pending" : item.status == 1 ? "Success" : item.status == 2 ? "Failed" : "Unknown"}</Tag>
                                        }
                                    },
                                    {
                                        title: "Price",
                                        render: (item) => {
                                            return this.props.formatPrice(item.total_price)
                                        }
                                    },
                                    {
                                        title: "Time send",
                                        render: (item) => {
                                            return item.time_send ? moment(item.time_send).format('YYYY/MM/DD HH:mm') : null
                                        }
                                    },
                                    {
                                        title: "Time created",
                                        render: (item) => {
                                            return moment(item.created_time).format('YYYY/MM/DD HH:mm')
                                        }
                                    },
                                    // {
                                    //     title: "Hành động",
                                    //     className: "text-right",
                                    //     render: (item) => {
                                    //         return <div>
                                    //             <Tooltip title="Xóa">
                                    //                 <Button size="small" type="danger" className="mr-2" onClick={() => {
                                    //                 }}><i className="far fa-trash"></i></Button>
                                    //             </Tooltip>
                                    //         </div>
                                    //     }
                                    // }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    onShowSizeChange: (current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: true
                                }}
                                rowKey="_id"
                                // rowSelection={{
                                //     type: "checkbox",
                                //     selectedRowKeys: this.state.dataSelected,
                                //     onChange: (values) => {
                                //         this.setState({
                                //             dataSelected: values
                                //         })
                                //     }
                                // }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
