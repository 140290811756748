import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Table, InputNumber, Alert, DatePicker, Tag } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';
import Chart from 'chart.js/auto';
import locale from 'antd/es/date-picker/locale/vi_VN';

export default class ClientView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            dataSelected: []
        };
        this.limit = 20;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/ow/device/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    update = async (values) => {
        try {
            this.setState({ loadingForm: true });
            values['max_send_network'] = {};

            Object.keys(values).forEach((key) => {
                if (this.props.networks.indexOf(key) > -1 && values[key]) {
                    values['max_send_network'][key] = values[key];
                }
            })

            let res = await fetchData({
                url: `api/v1/ow/device/${values._id}`,
                method: 'put',
                data: values
            });
            this.getListData();
            this.setState({ loadingForm: false, visibleForm: false, error: null })
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập device',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false, error: err.message || 'Đã có lỗi xảy ra' })
        }
    }

    getStatistical = async (values = {}) => {
        try {
            this.setState({ loadingStatistical: true });
            let params = {
                ...values
            }

            if (values['date']) {
                params['start'] = values['date'][0].format('YYYY-MM-DD');
                params['end'] = values['date'][1].format('YYYY-MM-DD');
                delete params['date'];
            }
            let response = await fetchData({
                url: `api/v1/ow/statistical?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                loadingStatistical: false
            }, () => {
                this.showChart(response.data);
            })
        } catch (err) {
            this.setState({ loadingStatistical: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    showChart = (options) => {
        var config = {
            type: 'bar',
            data: {
                labels: options.map((item) => item.date),
                datasets: [
                    {
                        label: '#Đơn hàng',
                        data: options.map((item) => item.total_order),
                        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
                        borderColor: ["rgba(54, 162, 235, 1)"],
                        borderWidth: 1
                    },
                    {
                        label: '#Gửi thành công',
                        data: options.map((item) => item.total_send_success),
                        backgroundColor: ["rgba(110,171,77,0.2)"],
                        borderColor: ["rgba(110,171,77)"],
                        borderWidth: 1
                    },
                    {
                        label: '#Gửi Lỗi',
                        data: options.map((item) => item.total_send_failed),
                        backgroundColor: ["rgba(255,0,0,0.2)"],
                        borderColor: ["rgba(255,0,0,1)"],
                        borderWidth: 1
                    },
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
        if (!window.myLine) {
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        } else {
            document.querySelector('#block-myChart #myChart').remove();
            document.getElementById('block-myChart').innerHTML = '<canvas id="myChart" width="400" height="120"></canvas>';
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        }

    }

    delete = async (ids) => {
        let _this = this;
        try {
            if (!ids || ids.length == 0) throw ({ message: "Chọn ít nhất 1 thiết bị để xóa" })
            Modal.confirm({
                title: 'Bạn có chắc muốn xóa',
                icon: <ExclamationCircleOutlined />,
                okText: 'Tiếp tục',
                okType: 'danger',
                cancelText: 'Hủy',
                className: "modal-confirm-h",
                onOk() {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let res = await _this.props.fetchData({
                                url: `api/v1/ow/device/delete`,
                                method: 'post',
                                data: {
                                    ids: ids
                                }
                            });
                            _this.getListData();
                            _this.props.notification({
                                content: 'Thao tác thành công!',
                                title: 'Xóa',
                                type: 'success'
                            })
                            resolve();
                        } catch (err) {
                            _this.props.notification({
                                content: err.message || 'Đã có lỗi xảy ra',
                                title: 'Xóa',
                                type: 'error'
                            })
                            reject();
                        }
                    })
                },
                onCancel() { },
            });
        } catch (err) {
            _this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Xóa',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="content" >
                <Modal
                    title={'Config device'}
                    visible={this.state.visibleForm}
                    onCancel={() => this.setState({ visibleForm: false }, () => this.form.resetFields())}
                    className={"form-modal"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.form = evt}
                        onFinish={this.update}
                        className="form_type_1"
                    >
                        {
                            this.state.error ? <Alert message={this.state.error} type="error" /> : null
                        }
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="max_send_this_device" label="Giới hạn gửi / ngày"
                        >
                            <InputNumber min={0} style={{ with: "100%" }} />
                        </Form.Item>
                        <Form.Item label="Giới hạn gửi theo mạng / ngày"
                        >
                            <div className="row">
                                {
                                    this.props.networks.map((item) => {
                                        return <Form.Item name={item} label={item} className="mb-5 col-md-4">
                                            <InputNumber min={0} />
                                        </Form.Item>
                                    })
                                }
                            </div>
                        </Form.Item>
                        <Form.Item style={{ textAlign: "right", marginTop: "20px" }}>
                            <Button onClick={() => this.setState({ visibleForm: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingForm}>
                                Cập nhập
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={'Thống kê'}
                    visible={this.state.visibleFormChart}
                    onCancel={() => this.setState({ visibleFormChart: false }, () => this.formdetail.resetFields())}
                    className={"form-modal"}
                    footer={null}
                    width={800}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formdetail = evt}
                        className="form_type_1"
                    >
                        <Form.Item name="device_id" style={{pointerEvents: "none"}}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="date" className="text-right">
                            <DatePicker.RangePicker
                                locale={locale}
                                defaultValue={[moment().add(-7, 'day'), moment()]}
                                format={'DD-MM-YYYY'}
                                ranges={{
                                    'Tuần này': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                    'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                    'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                }}
                                onChange={(moments, dateString) => {
                                    this.getStatistical(this.formdetail.getFieldsValue());
                                }}
                                format="DD/MM/YYYY"
                                allowClear={false}

                            />
                        </Form.Item>

                        <Spin spinning={this.state.loadingStatistical}>
                            <div className="block-content" id="block-myChart">
                                <canvas id="myChart" width="400" height="120"></canvas>
                            </div>
                        </Spin>

                        <Form.Item style={{ textAlign: "right", marginTop: "20px", marginBottom: "0px" }}>
                            <Button onClick={() => this.setState({ visibleFormChart: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Quản lý thiết bị: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <Tooltip title="Làm mới">
                                    <Button className="mr-10" type="primary" onClick={() => this.getListData()}>
                                        <i className="far fa-refresh"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Xóa">
                                    <Button className="mr-10" type="danger" onClick={() => {
                                        this.delete(this.state.dataSelected)
                                    }}>
                                        <i className="far fa-trash"></i>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="device_id">
                                        <Input placeHolder="Tìm thiết bị" />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData('reset');
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Id",
                                        dataIndex: "device_id"
                                    },
                                    {
                                        title: "Tên",
                                        dataIndex: "device_name",
                                    },
                                    {
                                        title: "Tổng sim",
                                        dataIndex: "total_sim"
                                    },
                                    {
                                        title: "Sim online",
                                        dataIndex: "total_sim_online"
                                    },
                                    {
                                        title: "Giới hạn gửi / ngày",
                                        dataIndex: "max_send_this_device"
                                    },
                                    {
                                        title: "Trạng thái",
                                        dataIndex: "last_time",
                                        render: (last_time) => {
                                            return <Tag color={last_time >= Date.now() - 20 * 1000 ? "success" : "error"}>{last_time >= Date.now() - 20 * 1000 ? "On" : "Off"}</Tag>
                                        }
                                    },
                                    {
                                        title: "Hoạt động",
                                        render: (item) => {
                                            return item.last_time ? moment(item.last_time).format('HH:mm DD/MM/YYYY') : null
                                        }
                                    },
                                    {
                                        title: "Hành động",
                                        className: "text-right",
                                        render: (item) => {
                                            return <div>
                                                <Tooltip title="Quản lý sim">
                                                    <Button className="mr-2" size="small" type="primary" onClick={() => {
                                                        this.props.history.push(`/admin/sim/list?device_id=${item.device_id}`);
                                                    }}><i className="far fa-server"></i></Button>
                                                </Tooltip>
                                                <Tooltip title="Thống kê">
                                                    <Button className="mr-2" size="small" type="primary" onClick={() => {
                                                        this.setState({
                                                            visibleFormChart: true,
                                                        }, () => {
                                                            this.getStatistical({ device_id: item.device_id });
                                                            if (this.formdetail) this.formdetail.setFieldsValue(item);
                                                        })
                                                    }}><i className="far fa-chart-bar"></i></Button>
                                                </Tooltip>
                                                <Tooltip title="Chỉnh sửa">
                                                    <Button size="small" type="primary" onClick={() => this.setState({ visibleForm: true }, () => {
                                                        setTimeout(() => {
                                                            if (this.form) {
                                                                this.form.setFieldsValue(item);
                                                                if (item.max_send_network) {
                                                                    this.form.setFieldsValue(item.max_send_network);
                                                                }
                                                            }
                                                        })
                                                    })}><i className="far fa-edit"></i></Button>
                                                </Tooltip>
                                            </div>
                                        }
                                    }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: false
                                }}
                                rowKey="_id"
                                rowSelection={{
                                    type: "checkbox",
                                    selectedRowKeys: this.state.dataSelected,
                                    onChange: (values) => {
                                        this.setState({
                                            dataSelected: values
                                        })
                                    }
                                }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
