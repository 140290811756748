import React, { Component } from 'react';
import { Modal, Button} from 'antd';
import NumberFormat from 'react-number-format';
import { fetchData } from '../lib/apis';

export default class DetailPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.form = null;
    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.setState({
                loading: false
            })
        }
    }

    render() {
        return (
            <Modal
                title={"Payment"}
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                <div className="money-wallet">
                    <p className="">
                        <strong>Step 1</strong>: Login to Internet banking (e-banking)
                                                        </p>
                    <p className="">
                        <strong>Step 2</strong>: Make a money transfer to one of the following accounts:
                                                        </p>
                    <div className="block-header-default block-rounded p-3 mb-3">
                        {
                            this.props.service.banking ?
                                this.props.service.banking.map((item, i) => {
                                    item = item.split('|');
                                    return <p key={i}>
                                        Bank: {item[2] || ''}<br></br>
                                        Account holder: {item[1] || ''}<br></br>
                                        Account number: {item[0] || ''}<br></br>
                                    </p>
                                })
                                : null
                        }
                    </div>
                    <p>
                        <strong>Step 3</strong>: Enter the exact transfer content as follows:
                    </p>
                    {
                        this.props.detailBanking ? <div className="show-order">
                            <h3 className="block-title mb-2">Payment details</h3>
                            <p className="mb-2"><strong>- Contents:</strong> <span className="text-danger">{this.props.detailBanking.code}</span></p>
                            <p><strong>- Amount:</strong> <span className="text-danger"><NumberFormat value={this.props.detailBanking.total_payment_after_discount} displayType={'text'} thousandSeparator={true} />$</span></p>
                            <div className="block-header-default block-rounded p-3">
                                <p className="mb-0 text-danger">Note:</p>
                                <p className="mb-0 text-danger">**Please enter the exact transfer details as above.</p>
                                <p className="mb-0">After receiving the notice from the bank and the package is verified, the system will automatically activate the package after 2-5 minutes. In case you enter the wrong information or there is any problem that makes the system unable to automatically activate, please <a href={this.props.service.link_support} target="_blank">Contact admin</a> for earliest support.</p>
                            </div>
                        </div> : null
                    }
                </div>
            </Modal>
        )
    }
}
