import React from "react";
import { Link } from "react-router-dom";
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Pagination, Tooltip, Modal, Select, Form, Input, InputNumber, Upload, Checkbox, Table, Tag, Alert, Steps, DatePicker } from 'antd';
import qs from 'qs';
import moment from 'moment';
import { fetchData } from '../lib/apis';
import locale from 'antd/es/date-picker/locale/en_US';

const { Option } = Select;

export default class TaskView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        };
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == "reset") this.page = 1;
            let query = {
                limit: this.limit,
                page: this.page,
            }
            if (this.formFilter) {
                query = {
                    ...query,
                    ...this.formFilter.getFieldsValue()
                }
                delete query['date_picker'];
            }

            let response = await fetchData({
                url: `api/v1/order/task/list?${qs.stringify(query)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false,
                listTypeOrder: response.type || []
            })
        } catch (err) {
            this.setState({ loading: false })
        }
    }

    exportData = async (values) => {
        try {
            this.setState({ loadingFormExport: true })
            await this.props.onExport({
                data: this.state.listData,
                fileName: values['fileName'],
                fileExtension: '.xlsx',
                format_export: ["recipient.sim", "status", "created_time", "content"],
                export_title: {
                    "recipient.sim": "Khách hàng",
                    "status": "Thông báo",
                    "created_time": "Thời gian gửi",
                    "content": "Nội dung tin nhắn"
                }
            })
            this.setState({ loadingFormExport: false, errorExport: null })
        } catch (err) {
            this.setState({ loadingFormExport: false, errorExport: typeof err == "string" ? err : err.message || "An error occurred" })
        }
    }

    updateTask = async (ids) => {
        try {
            this.setState({ loadingUpdate: true, taskIdsUpdate: ids });
            
            let response = await fetchData({
                url: `api/v1/order/task/stop`,
                method: 'post',
                data: {
                    ids: ids
                }
            });
            this.setState({
                loadingUpdate: false,
                taskIdsUpdate: []
            })
            this.props.notification({
                type: 'success',
                title: 'Stop task',
                content: "Đã gửi lệnh tạm dừng, hệ thống đang xử lý!"
            })
        } catch (err) {
            this.setState({ loadingUpdate: false, taskIdsUpdate: [] });
            this.props.notification({
                type: 'error',
                title: 'Stop task',
                content: err.message || 'An error occurred'
            })
        }
    }

    render() {
        return (
            <div className="content">
                <Modal
                    title="Export data"
                    visible={this.state.visibleFormExport}
                    onCancel={() => this.setState({ visibleFormExport: false, errorExport: null }, () => this.formExport.resetFields())}
                    footer={null}
                >
                    <Form
                        onFinish={this.exportData}
                        ref={(evt) => this.formExport = evt}
                        initialValues={{
                            filename: "file-export"
                        }}
                    >
                        {
                            this.state.errorExport ? <Alert type="error" message={this.state.errorExport} /> : null
                        }

                        <Form.Item
                            name="filename"
                            label="File name"
                        >
                            <Input addonAfter=".xlsx" />
                        </Form.Item>

                        <div className="text-right">
                            <Button type="primary" loading={this.state.loadingFormExport} htmlType="submit">
                                Export
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">All: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">

                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <Form.Item name="start_time" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="end_time" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="task_name">
                                        <Input placeHolder="Task name" />
                                    </Form.Item>
                                </div>
                                <div className="col-auto pl-5 pr-5">
                                    <Form.Item name="date_picker">
                                        <DatePicker.RangePicker
                                            locale={locale}
                                            defaultValue={[null, null]}
                                            ranges={{
                                                'This week': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                                'This month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                            }}
                                            onChange={(moments, dateString) => {
                                                this.formFilter.setFieldsValue({
                                                    start_time: dateString[0] ? moment(dateString[0]).unix() * 1000 : "",
                                                    end_time: dateString[1] ? moment(dateString[1]).unix() * 1000 : 0
                                                })
                                                this.getListData();
                                            }}
                                            format="YYYY/MM/DD HH:mm"
                                            allowClear={[true, true]}
                                            showTime
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Option value="">Status</Option>
                                            <Option value={0}>Pending</Option>
                                            <Option value={1}>Success</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-auto pl-5 pr-5">
                                    <Tooltip title="Reset filter">
                                        <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}>Reset</Button>
                                    </Tooltip>
                                </div>
                                <div className="col-auto pl-5 pr-5">
                                    <Tooltip title="Refresh">
                                        <Button type="primary" onClick={() => this.getListData()} >
                                            <i className="far fa-refresh"></i>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Task name",
                                        dataIndex: "task_name",
                                    },
                                    {
                                        title: "Status",
                                        render: (item) => {
                                            return <Tag color={item.status == 0 ? "processing" : item.status == 1 ? "success" : "error"}>{item.status == 0 ? "Pending" : item.status == 1 ? "Success" : item.status == 2 ? "Failed" : "Unknown"}</Tag>
                                        }
                                    },
                                    {
                                        title: "Target",
                                        dataIndex: "num_target"
                                    },
                                    {
                                        title: "Success",
                                        dataIndex: "num_success"
                                    },
                                    {
                                        title: "Failed",
                                        dataIndex: "num_failed"
                                    },
                                    {
                                        title: "Price",
                                        render: (item) => {
                                            return this.props.formatPrice(item.total_price)
                                        }
                                    },
                                    {
                                        title: "Time created",
                                        className: "text-right",
                                        render: (item) => {
                                            return moment(item.created_time).format('YYYY/MM/DD HH:mm')
                                        }
                                    },
                                    this.props.user.is_admin ? {
                                        title: "Action",
                                        className: "text-right",
                                        render: (item) => {
                                            return <div>
                                                <Tooltip title="Stop">
                                                    <Button disabled={item.status == 1} size="small" type="primary" className="ml-2" onClick={() => {
                                                        this.updateTask([item.task_id])
                                                    }} loading={this.state.loadingUpdate && this.state.taskIdsUpdate.indexOf(item.task_id) > -1}>
                                                        {
                                                            !this.state.loadingUpdate || this.state.taskIdsUpdate.indexOf(item.task_id) == -1 ?
                                                            <i className="far fa-pause"></i> : null
                                                        }
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        }
                                    } : {}
                                    // {
                                    //     title: "Hành động",
                                    //     className: "text-right",
                                    //     render: (item) => {
                                    //         return <div>
                                    //             <Tooltip title="Export">
                                    //                 <Button size="small" type="primary" className="ml-2" onClick={() => {

                                    //                 }}><i className="far fa-download"></i></Button>
                                    //             </Tooltip>
                                    //         </div>
                                    //     }
                                    // }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    onShowSizeChange: (current, size) => {
                                        this.page = current;
                                        this.limit = size;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: true
                                }}
                                rowKey="_id"
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
