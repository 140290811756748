import React from "react";
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, DatePicker } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import qs from 'qs';
import Chart from 'chart.js/auto';
import locale from 'antd/es/date-picker/locale/vi_VN';

export default class StatisticalClienlView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statistical: [],
            total: null
        };
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async () => {
        try {
            this.setState({ loading: true });
            let params = {};
            console.log('this.formFilter', this.formFilter)

            if(this.formFilter){
                params = {...params, ...this.formFilter.getFieldsValue()};
                delete params['date_picker'];
            }

            let response = await this.props.fetchData({
                url: `api/v1/order/statistical?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                statistical: response.data,
                loading: false
            }, () => {
                this.showChart(response.data);
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    showChart = (options) => {
        var config = {
            type: 'bar',
            data: {
                labels: options.map((item) => item.date),
                datasets: [
                    {
                        label: '#Đơn hàng',
                        data: options.map((item) => item.total),
                        backgroundColor: ["rgba(54, 162, 235, 0.2)"],
                        borderColor: ["rgba(54, 162, 235, 1)"],
                        borderWidth: 1
                    },
                    {
                        label: '#Thành công',
                        data: options.map((item) => item.total_success),
                        backgroundColor: ["rgba(110,171,77,0.2)"],
                        borderColor: ["rgba(110,171,77)"],
                        borderWidth: 1
                    },
                    {
                        label: '#Lỗi',
                        data: options.map((item) => item.total_failed),
                        backgroundColor: ["rgba(255,0,0,0.2)"],
                        borderColor: ["rgba(255,0,0,1)"],
                        borderWidth: 1
                    },
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        }
        if (!window.myLine) {
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        } else {
            document.querySelector('#block-myChart #myChart').remove();
            document.getElementById('block-myChart').innerHTML = '<canvas id="myChart" width="400" height="120"></canvas>';
            var ctx = document.querySelector('#block-myChart #myChart').getContext('2d');
            window.myLine = new Chart(ctx, config);
        }

    }

    render() {
        return (
            <div className="content">
                <div className="block block-rounded" style={{ paddingBottom: "100px" }}>
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Statistical</h3>
                    </div>
                    <div className="block-header pb-0 pt-30">
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                            style={{width: "100%"}}
                            initialValues={{
                                start: moment().add(-7, 'day').format('YYYY-MM-DD'),
                                end: moment().format('YYYY-MM-DD'),
                                recipient_network: ""
                            }}
                        >
                            <Form.Item name="start" noStyle>
                                <Input type="hidden"/>
                            </Form.Item>
                            <Form.Item name="end" noStyle>
                                <Input type="hidden"/>
                            </Form.Item>

                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="task_name">
                                        <Input placeHolder="Task name"/>
                                    </Form.Item>
                                </div>
                                
                                <div className="col-auto pl-5 pr-5">
                                    <Form.Item name="date_picker">
                                        <DatePicker.RangePicker
                                            locale={locale}
                                            defaultValue={[moment().add(-7, 'day'), moment()]}
                                            ranges={{
                                                'This week': [moment().startOf("week").add(1, "day"), moment().endOf("week").add(1, "day")],
                                                'This month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                            }}
                                            onChange={(moments, dateString) => {
                                                this.formFilter.setFieldsValue({
                                                    start: dateString[0] ? moment(dateString[0]).format('YYYY-MM-DD') : "",
                                                    end: dateString[1] ? moment(dateString[1]).format('YYYY-MM-DD') : ""
                                                })
                                                this.getListData();
                                            }}
                                            format="YYYY/MM/DD"
                                            allowClear={[false, false]}
                                        />
                                    </Form.Item>
                                </div>

                                <div className="col-md-2 col-lg-2 pl-5 pr-5">
                                    <Form.Item name="recipient_network">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Network</Select.Option>
                                            {
                                                this.props.networks.map((item, i) => {
                                                    return <Select.Option value={item}>{item}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="col-auto pl-5 pr-5">
                                    <Tooltip title="Reset filter">
                                        <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}>Reset</Button>
                                    </Tooltip>
                                </div>

                            </div>
                        </Form>
                    </div>
                    <Spin spinning={this.state.loading} tip="loading..">
                        <div className="block-content" id="block-myChart">
                            <canvas id="myChart" width="400" height="120"></canvas>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}
