import React from "react";
import NumberFormat from 'react-number-format';
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Table, Alert, Tag } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class ManageUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            detailBanking: null
        };
        this.limit = 15;
        this.page = 1;
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/ow/user/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    update = async (values) => {
        try {
            this.setState({ confirmLoading: true })
            let res = await fetchData({
                url: `api/v1/ow/user/${values._id}`,
                method: 'put',
                data: values
            });
            this.getListData();
            this.setState({ confirmLoading: false })
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập người dùng',
                type: 'success'
            })
        } catch (err) {
            this.setState({ confirmLoading: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập người dùng',
                type: 'error'
            })
        }
    }

    create = async (values) => {
        try {
            this.setState({ loadingUpdate: true });

            let res = await this.props.fetchData({
                url: `api/v1/ow/user/create`,
                method: 'post',
                data: values
            });

            this.setState({ loadingUpdate: false, visibleFormCreate: false, error: null });
            this.formCreate.resetFields();
            this.getListData();
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập dữ liệu người dùng',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingUpdate: false, error: err.message || 'Đã có lỗi xảy ra' });
        }
    }

    render() {
        return (
            <div className="content" style={{ height: "100%", overflow: "auto" }}>
                <Modal
                    title={'Chỉnh sửa quyền'}
                    visible={this.state.visibleFormUpdate}
                    onCancel={() => this.setState({ visibleFormUpdate: null })}
                    className={"form-modal form-code-company"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formUpdateRole = evt}
                        onFinish={this.update}
                    >
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        {
                            this.props.user.is_admin ?
                                <Form.Item name="level"
                                    label="Quyền"
                                >
                                    <Select
                                        placeholder="Phân quyền"
                                    >
                                        <Select.Option value={1}>User</Select.Option>
                                        <Select.Option value={99}>Admin</Select.Option>
                                    </Select>
                                </Form.Item> : null
                        }
                        <Form.Item name="status"
                            label="Trạng thái"
                        >
                            <Select
                                placeholder="Trạng thái"
                            >
                                <Select.Option value={1}>Hoạt động</Select.Option>
                                <Select.Option value={2}>Khóa</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="discount"
                            label="Chiết khấu"
                        >
                            <Input />
                        </Form.Item>
                        <div style={{ textAlign: "right" }}>
                            <Button onClick={() => this.setState({ visibleFormUpdate: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.confirmLoading}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={'Thêm tài khoản'}
                    visible={this.state.visibleFormCreate}
                    onCancel={() => this.setState({ visibleFormCreate: false }, () => this.formCreate.resetFields())}
                    className={"form-modal"}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formCreate = evt}
                        onFinish={this.create}
                        className="form_type_1"
                        autoComplete='off'
                        initialValues={{
                            username: ""
                        }}
                    >
                        {
                            this.state.error ? <Alert message={this.state.error} type="error" /> : null
                        }
                        <Form.Item name="username" label="Tên đăng nhập"
                            rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="fullname" label="Họ và tên"
                            rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="password" label="Mật khẩu"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input />
                        </Form.Item>
                        <Form.Item name="discount"
                            label="Chiết khấu"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item style={{ textAlign: "right", marginTop: "20px" }}>
                            <Button onClick={() => this.setState({ visibleFormCreate: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingCreate}>
                                Thêm mới
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">All: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <Tooltip title="Refresh">
                                    <Button className="mr-10" type="primary" onClick={() => this.getListData()}>
                                        <i className="far fa-refresh"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Thêm người dùng">
                                    <Button className="mr-10" type="primary" onClick={() => this.setState({ visibleFormCreate: true })}>
                                        <i className="far fa-plus"></i>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="keyword">
                                        <Input placeHolder="Tìm tên người dùng" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="status">
                                        <Select onChange={(e) => this.props.handleFilterData({ type: 'select', input: e, getFunction: this.getListData })}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>Hoạt động</Select.Option>
                                            <Select.Option value={2}>Tạm khóa</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData('reset');
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Tên",
                                        dataIndex: "fullname"
                                    },
                                    {
                                        title: "level",
                                        dataIndex: "level",
                                        render: (item) => item >= 99 ? "admin" : "user"
                                    },
                                    {
                                        title: "Số dư",
                                        dataIndex: "balance",
                                        render: (item) => this.props.formatPrice(item)
                                    },
                                    {
                                        title: "Chiết khấu",
                                        dataIndex: "discount",
                                        render: (item) => item+'%'
                                    },
                                    {
                                        title: "Trạng thái",
                                        render: (item) => {
                                            return item.status == 1 ? "Hoạt động" : "Tạm khóa"
                                        }
                                    },
                                    {
                                        title: "Hoạt động",
                                        render: (item) => {
                                            return item.last_time ? moment(item.last_time).format('HH:mm DD/MM/YYYY') : null
                                        }
                                    },
                                    {
                                        title: "Hành động",
                                        className: "text-right",
                                        render: (item) => {
                                            return <div>
                                                <Tooltip title="Sửa">
                                                    <Button size="small" type="primary" onClick={() => this.setState({ visibleFormUpdate: true }, () => {
                                                        setTimeout(() => {
                                                            if (this.formUpdateRole) this.formUpdateRole.setFieldsValue(item)
                                                        })
                                                    })}><i className="far fa-edit"></i></Button>
                                                </Tooltip>
                                            </div>
                                        }
                                    }
                                ]}
                                dataSource={this.state.listData}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: false
                                }}
                                rowKey="_id"
                                // rowSelection={{
                                //     type: "checkbox",
                                //     selectedRowKeys: this.state.dataSelected,
                                //     onChange: (values) => {
                                //         this.setState({
                                //             dataSelected: values
                                //         })
                                //     }
                                // }}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
