import React from "react";
import { fetchData } from '../../lib/apis';
import moment from 'moment';
import { Modal, Pagination, Form, Select, Input, Button, Tooltip, Spin, Table, InputNumber, Alert, Tag} from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class MessageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        };
        let url_search = new URLSearchParams(this.props.location.search);
        this.limit = 20;
        this.page = 1;
        this.device_id = url_search.get('device_id') || ""
    }
    componentDidMount = () => {
        this.getListData();
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
            }
            this.setState({ loading: true });

            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                url: `api/v1/ow/message/list?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Lấy dữ liệu",
                type: "error",
                content: err.message || "Đã có lỗi xảy ra!"
            })
        }
    }

    render() {
        return (
            <div className="content" >
                <div className="block block-rounded">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">All: {this.state.total}</h3>
                    </div>
                    <div className="block-content">
                        <div className="d-sm-flex align-items-center justify-content-between mb-10">
                            <div className="d-flex mb-10">
                                <Tooltip title="Quay lại">
                                    <Button className="mr-10" type="primary" onClick={() => {
                                        this.props.history.push('/admin/device')
                                    }}>
                                        <i className="far fa-arrow-left"></i>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Refresh">
                                    <Button className="mr-10" type="primary" onClick={() => this.getListData()}>
                                        <i className="far fa-refresh"></i>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>

                        <Form
                            initialValues={{
                                status: "",
                                device_id: this.device_id
                            }}
                            ref={(evt) => this.formFilter = evt}
                            onChange={(e) => this.props.handleFilterData({ type: 'form', input: e, getFunction: this.getListData })}
                        >
                            <div className="row">
                                <div className="col-md-2 col-lg-2 pr-5">
                                    <Form.Item name="device_id">
                                        <Input placeHolder="Tìm thiết bị" />
                                    </Form.Item>
                                </div>
                                <div className="col-lg-1 col-md-2 pl-5 pr-5">
                                    <Button className="d-flex align-items-center justify-content-center" type="primary" size={"middle"} onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData('reset');
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>

                        <div className="table-responsive">
                            <Table
                                dataSource={this.state.listData}
                                columns={[
                                    {
                                        title: "#",
                                        index: "#",
                                        render: (item, value, index) => {
                                            return (this.page * this.limit - this.limit) + index + 1
                                        }
                                    },
                                    {
                                        title: "Số nhận",
                                        dataIndex: "sim_to"
                                    },
                                    {
                                        title: "Số gửi",
                                        dataIndex: "sim_send"
                                    },
                                    {
                                        title: "Thiết bị",
                                        dataIndex: "device_id"
                                    },
                                    {
                                        title: "Cổng",
                                        dataIndex: "port"
                                    },
                                    {
                                        title: "Nội dung",
                                        dataIndex: "content",
                                        render: (item) => {
                                            return <textarea value={item}></textarea>
                                        }
                                    },
                                    {
                                        title: "Ngày nhận",
                                        className: "text-right",
                                        render: (item) => {
                                            return moment(item.time_send).format('HH:mm DD/MM/YYYY')
                                        }
                                    }
                                ]}
                                pagination={{
                                    total: this.state.total,
                                    pageSize: this.limit,
                                    onChange: (current) => {
                                        this.page = current;
                                        this.getListData();
                                    },
                                    current: this.page,
                                    showSizeChanger: false
                                }}
                                rowKey="_id"
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
